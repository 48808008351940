import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import TimeslotRow from "./timeslot-row";

const ScheduleTable = (props) => {
  const initialSchedule = props.initialData
    ? props.initialData
    : [
        {
          rowIndex: "",
          day: "",
          startTime: "",
          startTimeAmPm: "",
          endTime: "",
          endTimeAmPm: "",
        },
      ];

  let [schedule, updateSchedule] = useState(initialSchedule);

  const addTimeslot = () => {
    let newSchedule = [...schedule, { ...initialSchedule }];
    updateSchedule(newSchedule);
    props.handleScheduleUpdate(newSchedule);
  };

  const scheduleUpdateListener = (idx, data) => {
    let newSchedule = [...schedule];
    newSchedule[idx] = data;

    updateSchedule(newSchedule);
    props.handleScheduleUpdate(newSchedule);
  };

  const deleteTimeslotListener = (idx) => {
    const newSchedule = schedule.filter((_, _idx) => _idx !== idx);
    updateSchedule(newSchedule);
    props.handleScheduleUpdate(newSchedule);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Day</TableCell>
              <TableCell align="left">Start Time</TableCell>
              <TableCell align="left">AM/PM</TableCell>
              <TableCell align="left">End Time</TableCell>
              <TableCell align="left">AM/PM</TableCell>
              <TableCell align="left">
                <IconButton aria-label="add" color="primary" size="large">
                  <AddIcon onClick={addTimeslot} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {schedule.map((data, idx) => {
              return (
                <TimeslotRow
                  key={idx}
                  rowIndex={idx}
                  data={data}
                  scheduleUpdateListener={scheduleUpdateListener}
                  deleteTimeslotListener={deleteTimeslotListener}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ScheduleTable;
