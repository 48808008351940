import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { get } from "../../utility/request";
import Pagination from "../../components/navigation/pagination";

import RecruitmentCard from "../../components/generic/recruitment-card";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    height: "140",
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const Posts = () => {
  const classes = useStyles();

  const { user } = useAuth0();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    limitValue: null,
    totalCount: null,
    totalPages: null,
  });

  const [parties, setParties] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPosts = async (page) => {
    const res = await get("/user/parties", {
      params: { user_id: user.sub, page },
    });

    setPagination(res.data.pagination);
    setParties(res.data.parties);
  };

  const handlePageChange = async (event, value) => await getPosts(value);

  useEffect(() => {
    const fetch = async () => {
      await getPosts();
      setLoading(false);
    };

    fetch();
  }, []);

  if (loading) return <Loading></Loading>;

  return (
    <>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid container spacing={4}>
          {parties.map((party) => (
            <RecruitmentCard party={party} />
          ))}
        </Grid>
      </Container>

      <Pagination {...pagination} onChange={handlePageChange} />
    </>
  );
};

export default Posts;
