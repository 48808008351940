import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

import classVectorMap from "../../assets/class-icon-svg-map";
import { parseTwoDigitYear } from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRow: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    borderColor: "#c6b99b",
    margin: 2,
  },
}));

const getRoleColor = (job) => {
  if (job.role === "Tank") return "#3d4ea9";
  else if (job.role === "Healer") return "#3b632e";
  else if ("Melee DPS".includes(job.role)) return "#723333";
};

const getJobIcon = (job) => {
  let jobName = job.replace(" ", "").toLowerCase();
  return classVectorMap[jobName];
};

export default function LookForMembersPreview(props) {
  const classes = useStyles();

  const desiredJobsRow = (formData) => (
    <Grid container direction="row" className={classes.gridRow}>
      <Grid item xs={4}>
        <Typography variant="button">Desired Jobs</Typography>
      </Grid>
      <Grid item xs={8} className={classes.chips}>
        {formData.desiredComposition?.map((item, index) => (
          <Chip
            key={index}
            label={item.name}
            variant="outlined"
            size="small"
            className={classes.chip}
            style={{ color: getRoleColor(item.name) }}
            icon={getJobIcon(item.name)}
          />
        ))}
      </Grid>
    </Grid>
  );

  return (
    <div>
      <DialogTitle id="responsive-dialog-title">{"Submit form?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Let's make sure everything looks good first. Keep in mind that the
          more information you provide, the easier it'll be for other users to
          search for your recruitment post.
        </DialogContentText>
        <Divider />
        <div className={classes.root}>
          <Grid container>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Data Center</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{props.dataCenterName}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Content Types</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {props.formData.contentTypes?.map((item, index) => {
                    return (
                      <span key={index}>{(index ? ", " : "") + item.name}</span>
                    );
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">
                  {props.formData.lfm ? "Existing Jobs" : "Playable Jobs"}
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.chips}>
                {props.formData.existingComposition?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.name}
                    variant="outlined"
                    size="small"
                    className={classes.chip}
                    style={{
                      color: getRoleColor(item.name),
                    }}
                    icon={getJobIcon(item.name)}
                  />
                ))}
              </Grid>
            </Grid>

            {props.formData.lfg && desiredJobsRow(props.formData)}

            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Timezone</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{props.formData.timezone}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Schedule</Typography>
              </Grid>
              <Grid item xs={8}>
                {props.formData.schedule?.map((item, index) => (
                  <Typography
                    paragraph
                    key={index}
                  >{`${item.day}s ${item.startTime}${item.startAmPm} - ${item.endTime}${item.endAmPm}`}</Typography>
                ))}
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Description</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{props.formData.description}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.gridRow}>
              <Grid item xs={4}>
                <Typography variant="button">Contact Info</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{props.formData.contactInfo}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleClosePreview}
          color="secondary"
          size="small"
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleSubmit}
          color="primary"
          size="small"
          startIcon={<SaveIcon />}
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}
