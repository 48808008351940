import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth0 } from "@auth0/auth0-react";

import LookingForForm from "../../components/recruitment/looking-for-form";
import Loading from "../../components/loading";
import { get } from "../../utility/request";
import { getStaticData } from "../../hooks/use-static-data";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "75%",
    margin: "0 auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "65%",
  },
  contentTypes: {
    columns: "auto 3",
    display: "inline-block",
  },
  checkboxLabel: {
    marginRight: "0",
  },
  chip: {
    borderColor: "#c6b99b",
  },
  inputRoot: {
    "& .MuiChip-outlined": {
      borderColor: "#c6b99b",
    },
    "& .MuiChip-deleteIconSmall": {
      color: "#c6b99b",
    },
  },
}));

const Create = (props) => {
  const id = props?.match?.params?.id;
  const classes = useStyles();
  const { user } = useAuth0();

  const [formData, setFormData] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const getParty = async () =>
    await get(`/parties/${id}`, { params: { userId: user.sub } });

  useEffect(() => {
    if (props?.location?.state?.id && id === props.location.state.id) {
      setFormData(props.location.state);
      return;
    }

    const fetch = async () => {
      const party = await getParty();
      setFormData(party.data);
      setIsLoading(false);
    };

    fetch();
  }, []);

  if (isLoading) return <Loading />;

  if (user.sub !== formData.userId) {
    console.log("unauth");
    //TODO: redirect
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <LookingForForm
        {...props}
        {...getStaticData()}
        formData={formData}
        create={false}
      ></LookingForForm>
    </div>
  );
};

export default Create;
