import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { useAuth0 } from "@auth0/auth0-react";

import LookingForForm from "../../components/recruitment/looking-for-form";
import { getStaticData } from "../../hooks/use-static-data";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "75%",
    margin: "0 auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "65%",
  },
  contentTypes: {
    columns: "auto 3",
    display: "inline-block",
  },
  checkboxLabel: {
    marginRight: "0",
  },
  chip: {
    borderColor: "#c6b99b",
  },
  inputRoot: {
    "& .MuiChip-outlined": {
      borderColor: "#c6b99b",
    },
    "& .MuiChip-deleteIconSmall": {
      color: "#c6b99b",
    },
  },
}));

const Create = (props) => {
  const { user } = useAuth0();

  const classes = useStyles();

  const formData = () => ({
    userId: user.sub,
    dataCenterId: "",
    dataCenter: {},
    contentTypes: [],
    description: "",
    contactInfo: "",
    timezone: "",
    schedules: [
      {
        rowIndex: "",
        day: "",
        startTime: "",
        startAmPm: "",
        endTime: "",
        endAmPm: "",
      },
    ],
    desiredComposition: [],
    existingComposition: [],
    compositions: [],
    lfg: props.lfg,
    errorMessages: {},
  });

  return (
    <div className={classes.root}>
      <LookingForForm
        {...props}
        {...getStaticData()}
        formData={formData()}
        create={true}
      ></LookingForForm>
    </div>
  );
};

export default Create;
