import React, { useState, useEffect } from "react";

import Latest from "../components/homepage/latest";
import Hero from "../components/homepage/hero";
import Loading from "../components/loading";
import { get } from "../utility/request";
import Pagination from "../components/navigation/pagination";

const Home = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    limitValue: null,
    totalCount: null,
    totalPages: null,
  });

  const [parties, setParties] = useState([]);
  const [loading, setLoading] = useState(true);

  const getParties = async (page) => {
    const res = await get("/parties", {
      params: {
        page,
      },
    });

    setPagination(res.data.pagination);
    setParties(res.data.parties);
  };

  const handlePageChange = async (event, value) => await getParties(value);

  useEffect(() => {
    const fetch = async () => {
      await getParties();
      setLoading(false);
    };

    fetch();
  }, []);

  if (loading) return <Loading></Loading>;

  return (
    <div>
      <Hero />
      <Latest parties={parties} />

      <Pagination {...pagination} onChange={handlePageChange} />
    </div>
  );
};

export default Home;
