import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    height: "140",
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const RecruitmentCard = (props) => {
  const classes = useStyles();

  const { party } = props;

  return (
    <Grid item key={party.id} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardHeader
          title={`${party.dataCenter.name} Data Center`}
          subheader={party.updatedAt} //TODO: Format
          action={<Chip size="small" label={party.lfg ? "LFG" : "LFM"} />}
        />
        <CardMedia
          className={classes.cardMedia}
          image="https://source.unsplash.com/random"
          title="Image title"
        />
        <CardContent className={classes.cardContent}>
          <Typography>{party.description}</Typography>
        </CardContent>
        <CardActions>
          <Button
            component={RouterLink}
            size="small"
            color="primary"
            to={`/recruitment/${party.lfg ? "lfg" : "lfm"}/${party.id}`}
          >
            View
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default RecruitmentCard;
