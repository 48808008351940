import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import NativeSelect from "@mui/material/NativeSelect";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const populateTimes = () => {
  let times = [];
  let hours, minutes;
  for (let i = 0; i <= 690; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
    times.push(hours + ":" + minutes);
  }

  return times;
};

const TimeslotRow = (props) => {
  const [state, setState] = useState({
    day: props.data.day,
    startTime: props.data.startTime,
    startTimeAmPm: props.data.startTimeAmPm,
    endTime: props.data.endTime,
    endTimeAmPm: props.data.endTimeAmPm,
  });

  useEffect(() => {
    setState({
      day: props.data.day,
      startTime: props.data.startTime,
      startTimeAmPm: props.data.startTimeAmPm,
      endTime: props.data.endTime,
      endTimeAmPm: props.data.endTimeAmPm,
    });
  }, [
    props.data.day,
    props.data.startTime,
    props.data.startTimeAmPm,
    props.data.endTime,
    props.data.endTimeAmPm,
  ]);

  const handleChange = (event) => {
    let newState = state;
    newState[event.target.name] = event.target.value;
    setState((state) => {
      return { ...state, [event.target.name]: event.target.value };
    });

    props.scheduleUpdateListener(props.rowIndex, state);
  };

  const handleDelete = () => props.deleteTimeslotListener(props.rowIndex);

  return (
    <StyledTableRow>
      <TableCell align="left">
        <FormControl error={!state.day}>
          <NativeSelect
            value={state.day}
            onChange={handleChange}
            inputProps={{
              name: "day",
            }}
          >
            <option value=""></option>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </NativeSelect>
          {state.day ? null : <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </TableCell>
      <TableCell align="left">
        <FormControl error={!state.startTime}>
          <NativeSelect
            value={state.startTime}
            onChange={handleChange}
            inputProps={{
              name: "startTime",
            }}
          >
            <option value=""></option>

            {populateTimes().map((time, idx) => (
              <option key={idx} value={time}>
                {time}
              </option>
            ))}
          </NativeSelect>
          {state.startTime ? null : <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </TableCell>
      <TableCell align="left">
        <FormControl error={!state.startTimeAmPm}>
          <NativeSelect
            value={state.startTimeAmPm}
            onChange={handleChange}
            inputProps={{
              name: "startTimeAmPm",
            }}
          >
            <option value=""></option>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </NativeSelect>
          {state.startTimeAmPm ? null : (
            <FormHelperText>Required</FormHelperText>
          )}
        </FormControl>
      </TableCell>
      <TableCell align="left">
        <FormControl error={!state.endTime}>
          <NativeSelect
            value={state.endTime}
            onChange={handleChange}
            inputProps={{
              name: "endTime",
            }}
          >
            <option value=""></option>

            {populateTimes().map((time, idx) => (
              <option key={idx} value={time}>
                {time}
              </option>
            ))}
          </NativeSelect>
          {state.endTime ? null : <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </TableCell>
      <TableCell align="left">
        <FormControl error={!state.endTimeAmPm}>
          <NativeSelect
            value={state.endTimeAmPm}
            onChange={handleChange}
            inputProps={{
              name: "endTimeAmPm",
            }}
          >
            <option value=""></option>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </NativeSelect>
          {state.endTimeAmPm ? null : <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </TableCell>
      <TableCell align="left">
        <IconButton aria-label="delete" color="secondary" size="large">
          <DeleteIcon onClick={handleDelete} />
        </IconButton>
      </TableCell>
    </StyledTableRow>
  );
};

export default TimeslotRow;
