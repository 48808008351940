import { useEffect, useState } from "react";

let staticData = null;
const getStaticData = () => staticData || {};

const useStaticData = () => {
  const url = process.env.REACT_APP_API_URL;

  const [state, setState] = useState({
    contentTypes: [],
    dataCenters: [],
    jobs: [],
    loading: true,
    error: null,
  });

  // TODO: state management?
  const getContentTypes = async () => await fetch(`${url}/content`);
  const getDataCenters = async () => await fetch(`${url}/data_centers`);
  const getJobs = async () => await fetch(`${url}/jobs`);

  useEffect(() => {
    if (staticData?.contentTypes) {
      setState(staticData);
    }

    (async () => {
      try {
        const contentTypes = await (await getContentTypes()).json();
        const dataCenters = await (await getDataCenters()).json();
        const jobs = await (await getJobs()).json();
        const loading = false;

        const res = { contentTypes, dataCenters, jobs, loading };

        staticData = res;

        setState(res);
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
        console.error("servers busted?");
      }
    })();
  }, []);

  return state;
};

export { useStaticData, getStaticData };
