import classVectorMap from "../assets/class-icon-svg-map";

const getRoleColor = (job) => {
  if (job.role === "Tank") return "#3d4ea9";
  else if (job.role === "Healer") return "#3b632e";
  else if ("Melee DPS".includes(job.role)) return "#723333";
};

const getJobIcon = (job) => {
  let jobName = job.name.replace(" ", "").toLowerCase();
  return classVectorMap[jobName];
};

export { getRoleColor, getJobIcon };
