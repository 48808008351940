import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

//https://github.com/xivapi/classjob-icons
const map = {
  dancer: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M410 217q-2 27-14 47-9 13-40 47l-9 10q-46 49-63 80-4 6-9.5 19.5T266 439q-5 8-11 8t-11-8q-3-5-8.5-18.5T226 401q-17-31-63-80l-9-10q-31-34-40-47-12-20-14-47t11-46q12-17 31-22 25-6 49-22 38-26 64-73 26 47 64 73 23 16 49 22 19 5 31 22 13 19 11 46zm-33-8q-3-23-20-31-14-7-32-1-16 5-19 23-2 10 0 31 1 11 1 15-1 6-4 6-11 3-24-18-12-18-15-33 0-3 1-9.5t4-14.5 13.5-15.5T298 153q-8-2-43-41v1q-35 38-43 40 5 1 15.5 8.5T241 177t4 14.5 1 9.5q-3 15-15 33-13 21-24 18-3 0-4-6 0-4 1-15 2-21 0-31-3-18-19-23-18-6-32 1-17 8-20 31-2 20 11 41 8 12 28 35l8 9q17 18 45 43 14 13 19 19 9 8 11 16 2-8 11-16 5-6 19-19 28-24 45-43l8-9q20-23 28-35 13-21 11-41z"
      />
    </SvgIcon>
  ),
  gunbreaker: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3d4ea9"
        d="M420 114q-17 24-35 48l-3 6q-7 9-21 24L187 364q24 14 51.5 16t53-8.5 42.5-32 23-48.5q2-12 8.5-16.5t13-4 12.5 6 5 15.5q-7 46-35 77l2 9q4 13 4 19 1 10-4.5 14.5T349 413q-5-2-14-8-12-7-14-5-39 22-84 19t-81-30l-48 34q-7 4-10.5 5t-5.5 0l-3-3q-1-2 0-5.5t5-10.5l35-48 4-6q6-9 21-24l156-155q-25-18-56.5-19.5t-59 13-43.5 41-16 58.5q0 9-4 15.5t-13 7.5-15.5-6-6.5-17q0-51 30.5-91.5T205 123q3 0 6.5-12.5T217 93q5-9 11-10t13 8q4 5 10 16t8 11q44 4 79 31 15-14 19-15l50-34q9-6 14-6 2 0 3 2l1 2q1 2 0 5.5t-5 10.5z"
      />
    </SvgIcon>
  ),
  redmage: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M355 284q-3-1-6-3-7-5-18-5-27 0-46 20.5T266 345t16.5 45.5T327 412q6 1 10.5 6.5T340 432q-2 15-30 7-35-6-56-31t-21-66q0-24 11.5-46t31.5-36l1-1q13-7 15-26 3-67 11-141l1-13q2-18 3-23 1-8 5-8 3 0 5 8 1 5 2 22l2 14q5 54 9 116 2 17 6 30t8.5 15.5T359 262q17 9 21.5 9t1.5 5-11 8-16 0zm-186-45q3-2 23 12l10 7q7 5 15 7l3 1q6 0 11.5 3.5t2.5 6.5q-17 20-22 52-7 50 32 96l1 1q0 7-11.5 1T217 415t-11-14q-4-3-9-1l-10 7q-20 15-23 12-2-2 12-22l10-15q3-6 2-15-2-6-3-12-2-11-8-13l-18-4q-15-2-21-4-9-3-9-6t9-6q6-1 21-4l19-4q4-1 7.5-4.5t5.5-8 4-9.5l1-3q-1-7-7-17l-8-10q-14-20-12-23zm123 105q0-14 10-23.5t23.5-9.5 23 9.5 9.5 23-9.5 23.5-23 10-23.5-10-10-23z"
      />
    </SvgIcon>
  ),
  samurai: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M396 378q-32 38-78 52.5t-93 5.5q-19-3-33-10-27-12-13-26 7 4 54 7 33 3 65-9t57-37q15-16 18-37 3-23-11-43-9-12-23.5-17t-25.5-1q-13 5-15 20l-1-1 6 42q3 13-2 18-4 2-13 2h-63q-9 0-12-2-5-5-3-18l3-17q1-17-2-25t-10-10q-4-2-16-2-22-1-36-8-4-3-13-11-13-12-18-11-8 2-10 30 3 41 25 69 7 5 8 9t0 8v3q-1 5-5 7-8 4-23-12-26-28-36-68t-1.5-80.5T110 135q40-45 98.5-58T321 87q13 5 20.5 12t5 10.5T333 113t-22-4q-36-12-76-3-14 3-33 19-7 6-10 8-8 6-21 11-10 4-15 7l2-1q-4 3-7 6l-6 6q3-3-2 3-9 13-6 27t15 25q28 21 50.5 16.5T231 206q13-50 26-57 14 8 28 67l1 4q1 6 3 9t6 3l14-3q8-2 13-2.5t15 .5q23 2 48 21l1 1q12 14 15-11v1q-2-26-11-58-4-16-4-21 0-9 6-13 7-3 20 19 32 50 27.5 109T396 378zm-127-83q-6-69-12-76-7 7-13 76-1 12 7 12h11q8 0 7-12z"
      />
    </SvgIcon>
  ),
  astrologian: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3b632e"
        d="M266 168H142q-9 0-12.5 3t-2.5 17q-2 31 0 87 0 7 4.5 10.5t11 3.5 10.5-4 4-10q1-15 2-45v-1q0-20 5-25 3-4 15-3h1q25 2 49 1 13 0 18.5 5.5T253 234l2 91q0 21-1 27-2 7-8 8.5t-24 1.5l-37-1q-15 0-20-1t-6.5-5.5-.5-17.5v-17q0-1-2-3l-4-4q-63 40-81 109 27-28 48-45l3-3q6-7 9-7.5t.5 11T142 393q7 2 23 2h87q23-1 29.5-7t6.5-29l2-146q0-9-2-23-2-22-22-22zm85 11q-6-7-4-26v-9q0-18-2.5-24t-9-8-24.5-2l-101 1q-17 0-16 16 0 13 14 14l88 3q22 0 21 22v144q0 13 10 14 14 1 18-11 2-8 2-16v-9q1-20 0-30 1-25 6-37 6-14 20-24 10-6 30-17 25-14 38-23-25-5-49.5 1T351 179z"
      />
    </SvgIcon>
  ),
  darkknight: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3d4ea9"
        d="M165 270q38-12 53-28t13-46q-18 5-57 22-51 22-77 27 11-12 29-24 11-8 34-22 33-21 46-32-23-16-36-27-19-15-30-31 20-2 41 6 12 4 35 16l15 8q5-10 8-24 2-8 4-26 5-33 12-48 8 18 15 57 4 27 8 40 10-4 28-11 20-9 31-12 17-6 34-7-11 17-29 32-14 12-38 26 13 11 47 33 47 29 64 46-20-3-47-14-16-6-48-19.5T275 193q-3 28 16 48.5t56 29.5q-7 9-23 28-19 23-27 34-13 20-16 37l-2 4q-14 65-26 97l-21-100q-5-20-22-44-9-15-32-42zm90-25q-9 5-20 17l-7 8q-8 7-2 16l26 40q21-24 26.5-36.5T278 267q-4-6-23-22z"
      />
    </SvgIcon>
  ),
  machinist: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M387 245q-7-7-16-13L158 81q-9-7-15-8-14-5-23 11-7 12 5 21l183 131 9 6q17 10 24 19-5 5-10 8-28 14-31.5 27.5T313 335q8 10 16 19 13 13-4 27-8 7-11 6t-11-14q-12-18-17-28-8-16-23-8-11 6-3 22l18 36q12 20 23 34 10 11 16 11t15-11q7-9 19-27l6-8q11-16 13.5-23t-.5-14q-3-5-15-20l-16-17q-10-11-11.5-15t5.5-8q5-3 27-11l2-1q6-2 17-8l2-1q14-7 14-14t-8-17zm-105 30q3-13-.5-22T267 236q-20-13-61-42l-56-39q-5-4-11-4t-7.5 5.5 0 13 5 11.5 7.5 7l6 3q60 42 91 63l1 1q9 7 13 11 6 7 4 16-1 5-4.5 11t-8.5 7-10-2.5-4-8.5q1-14-4-20t-18-10q-4 10-5 23 0 7 1 22v5q1 12 7 15.5t18 1.5q25-3 35-13t16-38z"
      />
    </SvgIcon>
  ),
  ninja: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M299 357q3-2 7-5 5-5 9-10 8-9 14-20 18-31 18-66 1-47-31-97-21-33-54-55-26-16-58-25-22-6-46-7l-19-1 13 3q16 4 32 11 23 10 43 26 25 21 45 48l-9-3-11-3q-15-3-31-2-21 1-41 8-25 9-46 28-25 22-43 57t-20 74q-2 30 7 62 6 23 16 44l10 17-4-12q-5-16-7-34-2-25 2-50 4-32 19-63l1 9q2 10 7 19l3 9q17 33 47 52 42 28 105 25 51-2 94-31 30-20 53-51 12-16 17-27l-8 9q-11 12-26 23-20 14-44 24-30 11-64 14zm-71-18q-30 0-51-21.5T156 266t21-51 51-21 51.5 21 21.5 51-21.5 51.5T228 339z"
      />
    </SvgIcon>
  ),
  scholar: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3b632e"
        d="M430 227q-4-6-8-10-18-5-25 9-3 7-3.5 16.5t.5 13.5q7 15 7 24 0 17-12.5 29.5T359 322t-29-12.5-12-29.5q0-26 16-44 10-11 36-27l18-12-2-1q-19-16-39-22-33-11-91-11t-91 11q-20 7-39 22l-2 1 18 12q26 16 36 27 16 18 16 44 0 11-5.5 20.5t-15 15.5-20.5 6-21-6-15.5-15.5T111 281t7-25q1-4 .5-13.5T115 226q-7-14-25-9-4 4-8 10-7 11-12 24-6 16-6 29 0 31 19.5 55.5t50 31.5 58.5-7 41.5-42.5 6-58.5-32.5-49q12-5 25-7 8-2 24-2t24 2q13 2 25 7-25 19-32.5 49t6 58.5T320 360t58.5 7 50-31.5T448 280q0-13-6-29-5-13-12-24z"
      />
    </SvgIcon>
  ),
  summoner: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M397 177q-1-2-2.5-6t-4.5-6-13-2.5-12 13.5q-2 8-1 26v9q-1 8-10 11-5 2-17 3h-1q-4 0-17-6-8-4-27-16-23-13-34-19-21-11-88-65-24-19-29-22l-8-6q-10-7-14.5-8.5T109 87q29 65 46 124 14 48 21 91 4 30 5 55l-1 18-46 1q-12 0-14.5 4t2.5 13q1 4 22 9 24 6 51 7 36 2 66-5 37-8 64-29 61-60 74-122 7-33-1-72zM211 363q-2-52-10-99-10-56-26-90l26 24q30 26 45 31l18 7q31 11 46 17 24 12 28 23l-5 7q-21 33-40 50-34 29-82 30z"
      />
    </SvgIcon>
  ),
  blackmage: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M401 75q-9 6-46 33l-43 31q-26 19-37 10-5-6-6-15t2-16.5 13-15.5l3-3q2-2 1.5-8t-4-9.5T265 83t-31 16l-5 5q-28 21-46 37-39 36-73 85-28 23-39.5 56.5T67 351t33.5 60.5T161 445t69-3.5 56-39.5q50-35 84-73 16-17 37-45l5-6q12-15 17-31t1.5-19.5-10-4-8.5 2.5l-2 2q-9 10-16.5 13t-16.5 2.5-14-6.5q-10-11 10-37l31-42q27-38 33-47 8-13 10-46l1-1-18 2q-21 3-29 9zM189 408q-35 0-60-25t-25-60 25-60 60-25 60 25 25 60-25 60-60 25zm156-214q-14 21-18 36-4 18 6 30 8 9 34 7 12-1 23-4-5 8-12 16l-4 5q-13 17-24 28-14 14-38 33 6-30-3.5-59.5t-31-51-51-31T167 200q19-24 34-38 10-11 27-24l6-4q7-7 15-13l-2 12q-3 14-2 24 1 15 7 21 12 11 30 6 15-3 36-17t102-75l-1 1q-61 82-74 101z"
      />
    </SvgIcon>
  ),
  whitemage: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3b632e"
        d="M237 187q14 0 24-10t10-23.5-10-23.5-23.5-10-23.5 10-10 23.5 10 23.5 23 10zm30-123q-6-1-11.5 1t-6.5 5.5 1 7.5 6 6l3 2q47 21 47 69 0 27-18.5 49T242 226q-20 0-36-7-9-3-21-12l-2-2q-9-6-14-1-3 4-1 12t5 12q16 19 53 34h1q13 5 13 13v136q0 13 5.5 25t10.5 12 10-12 5-25V277q0-5 4-12t9-9q17-8 34-29 27-32 27-74 0-34-23-59t-55-30z"
      />
    </SvgIcon>
  ),
  bard: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M189 334V175q0-7-12-9l-6 2q-6 3-6 7v159q0 4 6 7l6 2 6-2q6-3 6-7zm62 0V175q0-7-12-9l-6 2q-6 3-6 7v159q0 4 6 7l6 2 6-2q6-3 6-7zm63 0V175q0-7-12-9l-6 2q-6 3-6 7v159q0 4 6 7l6 2 6-2q6-3 6-7zm61-275q-9 0-17 6.5T350 77t-3 6-9 1H153q-19 0-32 14-5 5-8 11.5t-3 12.5q0 10 3.5 15.5T127 143q7 0 13-3 3-2 8-7 7-7 11-7h168q9 0 16 5 9 6 9 18v177q0 10-13 28-15 20-35 32-25 16-52 16-59 0-100-31l-1-1q-4-3-9-4t-7.5 1.5 0 12T142 394q12 13 35 27 37 22 75 22 28 0 61.5-18.5T370 380q24-28 24-52V156q0-6 4-15t4-15V84q0-7-9.5-16T375 59z"
      />
    </SvgIcon>
  ),
  dragoon: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M370 172h-19l-8 10q-10 10-15 10t-8.5-7.5T316 170V88q0-10-7-23-3-7-6-11l-7 12q-7 13-7 22v88l-2 3q-4 4-10 8-10 7-21 9-11-2-21-9-6-4-10-8l-2-3V88q0-14-14-34l-6 11q-7 13-7 23v82q0 7-3.5 14.5T184 192q-8 0-23-20h-19q-3 0-5.5 2t-2.5 4.5 37 84.5q27 60 30 67 4 9 21 62l10 29q2 7 7 12t8 5h18q3 0 8-5t7-12l10-29q17-53 21-62 3-7 30-67 37-82 37-84.5t-2.5-4.5-5.5-2zm-47 72q-2 7-11.5 17.5T293 278l-5 3q-6 4-9.5 6.5t-7 7-3.5 6.5 6 10q9 10 9 13v28q0 3-9 15-5 7-6 9-6 13-6 22 0 3-3 5l-3 1-3-1q-3-2-3-5 0-9-6-22-1-2-6-9-9-12-9-15v-28q0-3 9-13 6-8 6-10t-3.5-6.5-7-7-9.5-6.5l-5-3q-9-6-18.5-16.5T189 244l-5-20 3-4q4-6 8-9 9-5 15-5 2 0 7 6 11 12 17 15 15 6 22 5h5q7-1 17-5 6-3 17-15 5-6 7-6 6 0 13.5 5t12.5 13z"
      />
    </SvgIcon>
  ),
  warrior: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3d4ea9"
        d="M448 256q-1-7-1-13-2-16-6-33-6-22-16-41-13-25-38-46.5T351 104q-8 3-13 7.5t-7 15.5l-2 11q-2 11-5 17-3 8-12 18t-23 15q-10 3-23 4h-10q-10 1-22-1-22-4-33-17-10-11-13-19-3-6-5-17l-2-11q-2-11-7-15.5t-13-6.5q-11-4-36 17.5T87 169q-10 19-16 41-4 17-6 33l-1 13q0 12 3 29 6 32 19.5 57.5t38.5 47 37 18.5q7-3 12-7.5t7-15.5l3-11q2-11 4-17 4-8 13-19 11-13 34-17 12-2 21-1 11-1 22 1 23 4 33 17 9 11 13 19 2 6 4 17l3 11q2 11 7 15.5t13 6.5q11 4 36-17.5t38-46.5q14-27 20-60 3-16 3-27zm-59 65q-11 21-24 33l-4-10q-4-12-11-22-10-15-23-23-16-10-35-15-13-4-26-4h-10q-9-1-23 1-25 4-47 18-14 9-23 23-7 10-12 22l-3 10-9-9q-9-11-16-24t-10-30q-3-12-4-25v-10q0-10 1-22 3-25 13-43 7-13 16-24l8-9 4 10q4 12 11 22 10 15 23 23 23 14 49 18 13 2 22 1 10 1 23-1 26-4 48-18 18-12 30-35 6-11 8-20l8 9q9 11 16 24 10 18 13 42 1 13 1 23 1 37-14 65z"
      />
    </SvgIcon>
  ),
  monk: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#723333"
        d="M269 120q19-16 28-38 5-10 5-18h-9q-11 2-22 6-16 6-30 17L98 208q-20 17-29 38-4 11-5 18h9q11-1 22-6 16-6 30-17l72-61zm73 87q13-12 22-26 6-11 9-21l2-9h-9q-11 1-22 6-16 6-30 17L170 295q-13 12-22 26-6 11-9 21l-2 9h9q11-1 22-6 16-6 30-17l72-61zm45 54l-72 61-72 60q-19 16-28 38-5 10-5 18h9q11-1 22-6 16-6 30-17l143-121q14-12 23-26 6-11 9-21l2-9h-9q-11 1-22 6-16 6-30 17z"
      />
    </SvgIcon>
  ),
  paladin: (
    <SvgIcon viewBox="0 0 512 512">
      <path
        fill="#3d4ea9"
        d="M388 71q-4 0-16.5 16T355 103h-71q-2 0-10-17l-3-7q-5-9-10-13l-5-2-5 2q-5 4-10 13l-3 7q-8 17-10 17h-71q-4 0-16.5-16T124 71q-7 0-13.5 7T104 96v132q0 49 17 90 16 39 45 67 24 23 59 45 17 11 31 18 13-7 31-18 35-22 59-45 29-28 45-67 17-41 17-90V96q0-11-6.5-18T388 71zM240 393q-5-3-11-6-13-9-25-19-17-15-30-31-16-20-24-48-6-22-6-41V136h96v257zm98-56q-18 23-44 42-13 9-22 14V136h96v112q0 19-6 41-8 28-24 48z"
      />
    </SvgIcon>
  ),
};

export default map;
