import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useAuth0 } from "@auth0/auth0-react";

import DeleteRecruitmentConfirmation from "../../components/recruitment/delete-recruitment-confirmation";
import DeactivateRecruitmentConfirmation from "../../components/recruitment/deactivate-recruitment-confirmation";
import Loading from "../../components/loading";
import { get, destroy } from "../../utility/request";
import { getJobIcon, getRoleColor } from "../../utility/job-helpers";
import { getStaticData } from "../../hooks/use-static-data";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    width: "75%",
    margin: "0 auto",
  },
  pageTitle: {
    textAlign: "center",
  },
}));

export default function ViewLookForMembersPost(props) {
  const id = props.match.params.id;
  const classes = useStyles();
  const emptyValue = "Not provided";
  console.log(getStaticData().dataCenters);
  const [state, setState] = useState({
    userId: null,
    dataCenterId: "",
    contentTypes: [],
    description: "",
    contactInfo: "",
    timezone: "",
    schedule: [
      {
        rowIndex: "",
        day: "",
        startTime: "",
        startAmPm: "",
        endTime: "",
        endAmPm: "",
      },
    ],
    desiredComposition: [],
    existingComposition: [],
    lfg: false,
    errorMessages: {},
    ...getStaticData(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [edit, setEditable] = useState(false);

  const [openDeactivateConfirmation, setOpenDeactivateConfirmation] =
    useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (user.id !== state.id) return;

    destroy(`/parties/${id}`);
    setRedirect(true);
  };

  const handleDeactivate = () => {};

  const handleEdit = () => {
    setEditable(true);
  };

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
    handleMenuClose();
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeactivateConfirmation = () => {
    setOpenDeactivateConfirmation(true);
    handleMenuClose();
  };

  const handleCloseDeactivateConfirmation = () => {
    setOpenDeactivateConfirmation(false);
  };

  const getParty = async () => await get(`/parties/${id}`);

  useEffect(() => {
    const fetch = async () => {
      setState({
        ...(await getParty()).data,
      });
      setIsLoading(false);
    };

    fetch();
  }, []);

  const { user } = useAuth0();
  const isOwner = user.sub === state.userId;

  if (isLoading) return <Loading />;
  if (redirect) return <Redirect to="/" />;
  if (isOwner && edit)
    return (
      <Redirect
        to={{
          pathname: `/recruitment/${state.lfg ? "lfg" : "lfm"}/update/${id}`,
          state: { formData: state },
        }}
      />
    );

  const groupJobRoles = (items) =>
    items.reduce(
      (memo, job) => {
        switch (job.role) {
          case "Tank":
            memo.tanks.items.push(job);
            break;
          case "Healer":
            memo.healers.items.push(job);
            break;
          case "Melee DPS":
            memo.meleeDps.items.push(job);
            break;
          case "Physical Ranged DPS":
            memo.physicalRangedDps.items.push(job);
            break;
          case "Magical Ranged DPS":
            memo.magicalRangedDps.items.push(job);
            break;
        }

        return memo;
      },
      {
        tanks: {
          displayName: "Tanks",
          items: [],
        },
        healers: {
          displayName: "Healers",
          items: [],
        },
        meleeDps: {
          displayName: "Melee DPS",
          items: [],
        },
        physicalRangedDps: {
          displayName: "Physical Ranged DPS",
          items: [],
        },
        magicalRangedDps: {
          displayName: "Magical Ranged DPS",
          items: [],
        },
      }
    );

  let filledJobs = groupJobRoles(
    state.compositions.filter((composition) => !composition.lfm)
  );
  let lookingForJobs = groupJobRoles(
    state.compositions.filter((composition) => composition.lfm)
  );

  const filledCompositionTable = () => (
    <>
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary">
          {Object.values(filledJobs).reduce(
            (sum, role) => (sum += role.items.length),
            0
          )}{" "}
          of 8 slots already filled
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {Object.values(filledJobs).map((role) => (
              <TableRow>
                <TableCell align="left" width="30%">
                  {role.displayName}
                </TableCell>

                <TableCell align="left" width="70%">
                  {role.items.length > 0
                    ? role.items.map((item, idx) => (
                        <Chip
                          key={idx}
                          label={item.name}
                          variant="outlined"
                          size="small"
                          className={classes.chip}
                          style={{
                            color: getRoleColor(item),
                          }}
                          icon={getJobIcon(item)}
                        />
                      ))
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const availableCompositionTable = () => (
    <>
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary">
          {Object.values(lookingForJobs).reduce(
            (sum, role) => (sum += role.items.length),
            0
          )}{" "}
          spots available for the following role(s)
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {Object.values(lookingForJobs).map((role) =>
              role.items.length ? (
                <TableRow>
                  <TableCell align="left" width="30%">
                    {role.displayName}
                  </TableCell>

                  <TableCell align="left" width="70%">
                    {role.items.length > 0
                      ? role.items.map((item, idx) => (
                          <Chip
                            key={idx}
                            label={item.name}
                            variant="outlined"
                            size="small"
                            className={classes.chip}
                            style={{
                              color: getRoleColor(item),
                            }}
                            icon={getJobIcon(item)}
                          />
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const userActions = () => (
    <>
      <IconButton
        onClick={handleMenuClick}
        aria-label="menu"
        color="primary"
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>

        <MenuItem onClick={handleOpenDeactivateConfirmation}>
          <ListItemIcon>
            <PowerSettingsNewIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary="Deactivate" />
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteConfirmation}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid container direction="row">
          <Grid item xs={12} className={classes.pageTitle}>
            <Typography variant="h4" component="h2">
              {state.lfg ? "LFG Recruitment Post" : "LFM Recruitment Post"}{" "}
              {isOwner ? userActions() : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid container direction="row" id="back-to-top-anchor">
            <Grid item xs={12} className={classes.pageSubtitle}>
              <Typography variant="subtitle1">
                Last Updated: {state.updatedAt}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid item xs={8}>
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <PublicOutlinedIcon
                    color="secondary"
                    className={classes.icon}
                  />
                  Data Center
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" component="p">
                  {state.dataCenter ? state.dataCenter.name : emptyValue}
                </Typography>
              </Grid>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <ScheduleOutlinedIcon
                    className={classes.icon}
                    color="secondary"
                  />
                  Timezone
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" component="p">
                  {state.timezone ? `${state.timezone}` : `${emptyValue}`}
                </Typography>
              </Grid>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <EventOutlinedIcon
                    className={classes.icon}
                    color="secondary"
                  />
                  Schedule
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" component="p">
                  {state.schedules.length > 0 ? (
                    <List aria-label="content types">
                      {state.schedules.map((item, idx) => (
                        <ListItem key={idx} className={classes.listItem}>
                          <ListItemIcon>
                            <FiberManualRecordSharpIcon
                              className={classes.listIcon}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${item.day}s ${item.startTime}${item.startTimeAmPm}-${item.endTime}${item.endTimeAmPm}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    `${emptyValue}`
                  )}
                </Typography>
              </Grid>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <DoneAllOutlinedIcon
                    className={classes.icon}
                    color="secondary"
                  />
                  Goals
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color="textSecondary">
                  {state.lfg
                    ? "Type of content you plan to tackle"
                    : "Types of content that the raid group plans to tackle"}
                </Typography>
              </Grid>
              <Grid item>
                {state.contents ? (
                  <List aria-label="content types">
                    {state.contents.map((ct) => (
                      <ListItem key={ct.id} className={classes.listItem}>
                        <ListItemIcon>
                          <FiberManualRecordSharpIcon
                            className={classes.listIcon}
                          />
                        </ListItemIcon>
                        <ListItemText primary={ct.displayName} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  `${emptyValue}`
                )}
              </Grid>
              <Divider className={classes.divider} />
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <GroupOutlinedIcon
                    className={classes.icon}
                    color="secondary"
                  />
                  {state.lfg ? "Playable Jobs" : "Party Composition"}
                </Typography>
              </Grid>
              {state.lfg ? (
                filledCompositionTable()
              ) : (
                <>
                  {" "}
                  {filledCompositionTable()} {availableCompositionTable()}{" "}
                </>
              )}
              <Divider className={classes.divider} />
              <Grid item>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.sectionTitle}
                  color="primary"
                >
                  <InfoOutlinedIcon
                    color="secondary"
                    className={classes.icon}
                  />
                  Additional Information
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" component="p">
                  {state.description ? `${state.description}` : `${emptyValue}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.contact}>
          <Grid item>
            <Paper elevation={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.cardTitle}
                    color="textSecondary"
                  >
                    Want to request more info or express your interest?
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    className={classes.sectionTitle}
                    color="primary"
                  >
                    <ChatBubbleOutlineOutlinedIcon
                      className={classes.icon}
                      color="secondary"
                    />
                    Contact Details
                  </Typography>

                  <Typography variant="body1" component="p">
                    {state.contactInfo
                      ? `${state.contactInfo}`
                      : `${emptyValue}`}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <DeleteRecruitmentConfirmation
          handleCloseDeleteConfirmation={handleCloseDeleteConfirmation}
          handleDelete={handleDelete}
        />
      </Dialog>
      <Dialog
        open={openDeactivateConfirmation}
        onClose={handleCloseDeactivateConfirmation}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <DeactivateRecruitmentConfirmation
          handleCloseDeactivateConfirmation={handleCloseDeactivateConfirmation}
          handleDeactivate={handleDeactivate}
        />
      </Dialog>
    </div>
  );
}
