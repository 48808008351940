import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import RecruitmentCard from "../generic/recruitment-card";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    height: "140",
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const Latest = (props) => {
  const classes = useStyles();

  const { parties } = props;

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <Grid container spacing={4}>
        {parties.map((party) => (
          <RecruitmentCard party={party} />
        ))}
      </Grid>
    </Container>
  );
};

export default Latest;
