import React, { useState, useEffect } from "react";
import { tz } from "moment-timezone";

import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import NativeSelect from "@mui/material/NativeSelect";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from "@mui/material/Autocomplete";

import ScheduleTable from "./schedule-table";
import Preview from "./look-for-preview";
import { post, patch } from "../../utility/request";
import { getJobIcon, getRoleColor } from "../../utility/job-helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "75%",
    margin: "0 auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "65%",
  },
  contentTypes: {
    columns: "auto 3",
    display: "inline-block",
  },
  checkboxLabel: {
    marginRight: "0",
  },
  chip: {
    borderColor: "#c6b99b",
  },
  inputRoot: {
    "& .MuiChip-outlined": {
      borderColor: "#c6b99b",
    },
    "& .MuiChip-deleteIconSmall": {
      color: "#c6b99b",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LookForForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [state, setState] = useState(
    {
      ...props.formData,
      formData: props.formData,
      errorMessages: {},
    },
    () => console.log(state.lfg)
  );

  const [openPreview, setOpenPreview] = useState(false);
  const [scheduleError, setScheduleError] = useState(true);
  const [timezoneError, setTimezoneError] = useState(true);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    if (name === "timezone") {
      validateTimezone(event.target.value, state.schedule);
    }
  };

  const handleContentTypesChange = (event, values) =>
    setState((state) => ({
      ...state,
      contentTypes: values,
    }));

  const handleScheduleUpdate = (data) => {
    setState((state) => {
      return { ...state, schedule: data };
    });

    validateSchedule(data);
    validateTimezone(state.timezone, data);
  };

  const handleSubmit = async () => {
    const payload = {
      dataCenterId: state.dataCenterId,
      description: state.description,
      contactInfo: state.contactInfo,
      timezone: state.timezone,
      contents: state.contentTypes,
      compositions: [
        ...state.existingComposition.map((item) => ({ ...item, lfm: false })),
        ...state.desiredComposition.map((item) => ({ ...item, lfm: true })),
      ],
      schedules: state.schedule,
      lfg: state.lfg,
      userId: state.userId,
    };

    try {
      if (props.create) await post("/parties", payload);
      else await patch(`/parties/${state.id}`, payload);
      // TODO: show success or something?
    } catch (err) {
      console.log(err);
    }

    handleClosePreview();
  };

  const handleExistingJobsChange = (event, values) =>
    setState((state) => ({
      ...state,
      existingComposition: values,
    }));

  const handleDesiredJobsChange = (event, values) =>
    setState((state) => ({
      ...state,
      desiredComposition: values,
    }));

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const validateSchedule = (schedule) => {
    if (schedule.length === 0) {
      return setScheduleError(false);
    }

    for (let i = 0; i < schedule.length; i += 1) {
      for (let [key, value] of Object.entries(schedule[i])) {
        if (value === "") {
          return setScheduleError(true);
        }
      }
    }

    return setScheduleError(false);
  };

  const validateTimezone = (timezone, schedule) => {
    if (schedule.length > 0) {
      return setTimezoneError(timezone.length === 0);
    }

    if (schedule.length === 0) {
      return setTimezoneError(false);
    }
  };

  useEffect(() => {
    validateSchedule(state);
    validateTimezone(state.timezone, state.schedules);

    setState({
      ...state,
      existingComposition: state.compositions.map((item) => ({
        ...item,
        lfm: false,
      })),
      desiredComposition: state.compositions.map((item) => ({
        ...item,
        lfm: true,
      })),
      lfg: props.formData.lfg,
    });
  }, []);

  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off" className={classes.form}>
        <h1>{state.lfg ? "LFG Form" : "LFM Form"}</h1>

        <FormControl color="primary" className={classes.formControl}>
          <FormLabel component="legend">Data Center</FormLabel>
          <NativeSelect
            value={state.dataCenterId}
            defaultValue={state.dataCenter.id}
            onChange={handleChange}
            inputProps={{
              name: "dataCenterId",
              id: "data-center-native-helper",
            }}
          >
            <option value=""></option>
            {props.dataCenters.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </NativeSelect>
        </FormControl>

        <FormLabel component="legend">Content Types</FormLabel>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          defaultValue={state.contents}
          options={Object.values(props.contentTypes)}
          disableCloseOnSelect
          getOptionLabel={(option) => option.displayName}
          onChange={handleContentTypesChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.displayName}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />

        <FormControl className={classes.formControl}>
          <FormLabel component="legend">
            {state.lfg
              ? "Select all jobs that you can play for the static"
              : "Select all jobs that are currently filled"}
          </FormLabel>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            classes={{ inputRoot: classes.inputRoot }}
            options={props.jobs}
            defaultValue={state.compositions.filter(
              (compositions) => !compositions.lfm
            )}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={handleExistingJobsChange}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  label={option.name}
                  className={classes.chip}
                  style={{
                    color: getRoleColor(option),
                  }}
                  icon={getJobIcon(option)}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </FormControl>
        <br />
        <br />

        {state.lfg && (
          <FormControl className={classes.formControl}>
            <FormLabel component="legend">
              Select all jobs that you're accepting
            </FormLabel>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              classes={{ inputRoot: classes.inputRoot }}
              options={props.jobs}
              defaultValue={state.compositions.filter(
                (compositions) => compositions.lfm
              )}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={handleDesiredJobsChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={option.name}
                    className={classes.chip}
                    style={{
                      color: getRoleColor(option),
                    }}
                    icon={getJobIcon(option)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        )}
        <br />
        <br />
        <FormControl color="primary" className={classes.formControl}>
          <FormLabel component="legend">Timezone</FormLabel>
          <NativeSelect
            value={state.timezone}
            onChange={handleChange}
            inputProps={{
              name: "timezone",
              id: "timezone-native-helper",
            }}
          >
            <option value=""></option>
            {tz.names().map((timezone, idx) => (
              <option key={idx} value={timezone}>
                {timezone}
              </option>
            ))}
          </NativeSelect>
          {timezoneError ? (
            <FormHelperText error>
              This field is required when the schedule contains at least one
              timeslot row
            </FormHelperText>
          ) : null}
        </FormControl>

        <br />
        <br />
        <div>
          <FormLabel component="legend">Availability</FormLabel>
          <ScheduleTable
            initialData={state.schedules}
            handleScheduleUpdate={handleScheduleUpdate}
          />
          {scheduleError ? (
            <FormHelperText error>
              One or more timeslot rows is missing required value(s)
            </FormHelperText>
          ) : null}
        </div>

        <br />
        <br />
        <FormLabel component="legend">Description</FormLabel>
        <TextField
          id="description"
          name="description"
          defaultValue={state.description}
          onChange={handleChange}
          style={{ margin: 8 }}
          placeholder="Is there anything else statics should know about you?"
          helperText=""
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <br />
        <br />
        <FormLabel component="legend">Contact Info</FormLabel>
        <TextField
          id="contact-info"
          name="contactInfo"
          defaultValue={state.contactInfo}
          onChange={handleChange}
          style={{ margin: 8 }}
          placeholder="How can you be contacted?"
          helperText=""
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <Button
          type="button"
          onClick={handleOpenPreview}
          variant="contained"
          color="primary"
          disabled={scheduleError || timezoneError}
        >
          Submit
        </Button>
      </form>

      <Dialog
        fullScreen={fullScreen}
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <Preview
          handleClosePreview={handleClosePreview}
          handleSubmit={handleSubmit}
          dataCenterName={
            props.dataCenters.find(
              ({ id }) => id.toString() === state.dataCenterId
            )?.name
          }
          formData={state}
        />
      </Dialog>
    </div>
  );
};

export default LookForForm;
