import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Link from "@mui/material/Link";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { Link as RouterLink } from "react-router-dom";

import HeroImage from "../../assets/hero.png";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    background: `no-repeat center url(${HeroImage})`,
    minWidth: "100%",
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

const Hero = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="hero" className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          FFXIV Recruitment
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Something about raid group recruitment here. Blah blah blah. Blah blah
          blah. Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah.
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMenu}
                endIcon={<ArrowDropDownIcon />}
                ref={anchorRef}
              >
                Start Recruiting
              </Button>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem onClick={handleClose}>
                        <Link
                          component={RouterLink}
                          to="/look-for-members"
                          underline="none"
                          color="inherit"
                        >
                          Look for members
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link
                          component={RouterLink}
                          to="/look-for-group"
                          underline="none"
                          color="inherit"
                        >
                          Look for groups
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary">
                Search
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
