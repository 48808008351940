import axios from "axios";

const baseConfig = {
  "Content-Type": "application/json",
  baseURL: process.env.REACT_APP_API_URL,
};

const get = async (url, config) =>
  axios({ ...baseConfig, url, ...config, method: "get" });

const post = async (url, payload, config) =>
  axios({ ...baseConfig, url, ...config, data: payload, method: "post" });

const destroy = async (url, config) =>
  axios({ ...baseConfig, url, ...config, method: "delete" });

const patch = async (url, payload, config) =>
  axios({ ...baseConfig, url, ...config, data: payload, method: "patch" });

export { get, post, destroy, patch };
