import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import "./App.css";

import ProtectedRoute from "./auth/protected-route";

import Loading from "./components/loading";
import AppBar from "./components/navigation/app-bar";
import LeftDrawer from "./components/navigation/drawer";
import Home from "./views/home";
import Profile from "./views/user/profile";
import Posts from "./views/user/posts";
import CreateLFGRecruitment from "./views/recruitment/create-lfg";
import CreateLFMRecruitment from "./views/recruitment/create-lfm";
import UpdateLFGRecruitment from "./views/recruitment/update-lfg";
import UpdateLFMRecruitment from "./views/recruitment/update-lfm";

import ViewRecruitment from "./views/recruitment/view";
import { useStaticData } from "./hooks/use-static-data";

const drawerWidth = 240;

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: +drawerWidth,
  },
}));

const App = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);

  const toggleDrawer = () => setOpenDrawer((openDrawer) => !openDrawer);

  const handleDrawerToggle = () => {
    toggleDrawer();
  };

  const { loading: loadingStaticData } = useStaticData();

  const { isLoading } = useAuth0();
  if (isLoading || loadingStaticData) {
    return <Loading />;
  }

  // TODO: hide protected routes when not logged in
  return (
    <div id="app" className="d-flex flex-column h-100">
      <AppBar openDrawer={openDrawer} handleDrawerToggle={handleDrawerToggle} />
      <LeftDrawer openDrawer={openDrawer} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer,
        })}
      >
        <Switch>
          <ProtectedRoute
            exact
            path="/recruitment/lfm/create"
            key="lfm/create"
            lfg={false}
            component={CreateLFMRecruitment}
          />
          <ProtectedRoute
            exact
            path="/recruitment/lfg/create"
            key="lfg/create"
            lfg={true}
            component={CreateLFGRecruitment}
          />
          <ProtectedRoute
            path="/recruitment/lfg/update/:id"
            key="recruitment/lfg/update"
            component={UpdateLFMRecruitment}
          />
          <ProtectedRoute
            path="/recruitment/lfm/update/:id"
            key="recruitment/lfm/update"
            component={UpdateLFGRecruitment}
          />

          <Route
            path="/recruitment/lfg/:id"
            render={(props) => <ViewRecruitment {...props} />}
          />
          <Route
            path="/recruitment/lfm/:id"
            render={(props) => <ViewRecruitment {...props} />}
          />
          <ProtectedRoute path="/user/posts" component={Posts} />
          <ProtectedRoute path="/user/profile" component={Profile} />
          <Route exact path="/" component={Home} />
        </Switch>
      </main>
    </div>
  );
};

const ThemeWrapper = () => {
  return (
    <ThemeProvider theme={theme}>
      <App></App>
    </ThemeProvider>
  );
};

export default ThemeWrapper;
