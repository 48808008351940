import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";

import CancelIcon from "@mui/icons-material/Cancel";
import SnoozeIcon from "@mui/icons-material/Snooze";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRow: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    borderColor: "#c6b99b",
    margin: 2,
  },
  cancelButton: {
    color: theme.palette.text.secondary,
  },
}));

export default function DeactivateRecruitmentConfirmation(props) {
  const classes = useStyles();

  return (
    <div>
      <DialogTitle id="responsive-dialog-title">{"Are you sure?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you really want to deactivate this recruitment post? This will hide
          your post from appearing in search results. You can reactivate this
          post later.
        </DialogContentText>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleCloseDeactivateConfirmation}
          color="secondary"
          size="small"
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={props.handleDeactivate}
          color="primary"
          size="small"
          startIcon={<SnoozeIcon />}
        >
          Deactivate
        </Button>
      </DialogActions>
    </div>
  );
}
