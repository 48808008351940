import React from "react";
import PaginationMui from "@mui/material/Pagination";

const Pagination = (props) => {
  const hidePagination = () =>
    props.currentPage === props.totalPages && props.currentPage === 1;

  return hidePagination() ? null : (
    <PaginationMui
      count={props.totalPages}
      page={props.currentPage}
      onChange={props.handlePageChange}
      hideNextButton={props.currentPage === props.totalPages}
      hidePrevButton={props.currentPage === 1}
    />
  );
};

export default Pagination;
