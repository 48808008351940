import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotesIcon from "@mui/icons-material/Notes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function LeftDrawer(props) {
  const classes = useStyles();

  const { user } = useAuth0();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <Link component={RouterLink} to="/" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>

          <Link component={RouterLink} to="/" underline="none" color="inherit">
            <ListItem button>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Browse All" />
            </ListItem>
          </Link>

          {user && (
            <Link
              component={RouterLink}
              to="/recruitment/lfm/create"
              underline="none"
              color="inherit"
            >
              <ListItem button>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Look For Members" />
              </ListItem>
            </Link>
          )}

          {user && (
            <Link
              component={RouterLink}
              to="/recruitment/lfg/create"
              underline="none"
              color="inherit"
            >
              <ListItem button>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Look For Group" />
              </ListItem>
            </Link>
          )}
        </List>
        {user && (
          <>
            <Divider />
            <Divider />

            <List>
              <Link
                component={RouterLink}
                to="/user/posts"
                underline="none"
                color="inherit"
              >
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Posts" />
                </ListItem>
              </Link>
            </List>
            <List>
              <Link
                component={RouterLink}
                to="/user/profile"
                underline="none"
                color="inherit"
              >
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </ListItem>
              </Link>
            </List>
            <Divider />
            <List>
              <Link
                component={RouterLink}
                to="/"
                underline="none"
                color="inherit"
              >
                <ListItem button>
                  <ListItemIcon>
                    <NotesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Changelog" />
                </ListItem>
              </Link>
            </List>
          </>
        )}
      </Drawer>
    </div>
  );
}
